.MapContainer {
    position: relative;
    width: 100%;
    /* padding-top: 52.1%; */
    padding-top: 55%;
  }
  .Map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
.App {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.App > .navbar:first-child {
  font-weight: bold;
  margin-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
}

.App > .navbar + .navbar.breadcrumb {
  margin-top: -15px;
	background: #fff;
	border-bottom: 1px solid #e8e8e8;
	border-radius: 0;
}

.App > .navbar .justify-content-end.navbar-nav .nav-item {
  margin-left: 1em;
}

.App > .navbar .justify-content-end.navbar-nav .nav-item:first-child {
  margin-left: 0;
}

.App > .container-fluid {
  flex-grow: 1;
}

.App > .centered.container-fluid {
	justify-content: center;
	align-items: center;
	display: flex;
}

.App > .container-fluid > .breadcrumb:first-child {
	margin: -15px -15px 15px;
}

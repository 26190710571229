body, html {
  min-height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Circular Std Book", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Circular Std", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.navbar.sticky-bottom {
  position:-webkit-sticky;
  position:sticky;
  bottom:0;
  z-index:1020;
  margin-top: 1rem;
}

form.full-form {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.full-form > .container-fluid {
  flex-grow: 1;
}
.breadcrumb .nav-item {
  padding-right: .75em;
  padding-left: .75em;
}

.breadcrumb .nav-item::before {
  content: "/";
  position: relative;
  left: -.75em;
}

.breadcrumb .nav-item:first-child {
  padding-left: 0;
}

.breadcrumb .nav-item:first-child::before {
  content: "";
}

.card {
  margin-bottom: 1rem;
}

.card.card-accent-danger {
  border-top-width: 2px;
  border-top-color: var(--danger);
}

.card.card-accent-success {
  border-top-width: 2px;
  border-top-color: var(--success);
}